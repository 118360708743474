const { env } = require('../environment')
const fetch = require('node-fetch')

const fetchArtists = () => {
  const url = env.getServiceHost() + 'motive/artistNames?version=3'

  return fetch(url).then(response => response.json())
}

module.exports = { fetchArtists }
