import { fetchArtists } from '../../services/artists_api'
import { useState, useEffect } from 'react'
import {
  localStorage_getItem,
  localStorage_setItem,
} from '../utils/LocalStorageHelper'
import { daysIntoYear } from '../utils/utils'

export const useArtistsData = () => {
  const [artists, setArtists] = useState([])

  const localeStorageId: string =
    process.env.STORAGE_PREFIX + '_artists_day' + daysIntoYear()

  useEffect(() => {
    const artistsFromLocalStorage = localStorage_getItem(localeStorageId)

    if (typeof artistsFromLocalStorage == 'string') {
      setArtists(JSON.parse(artistsFromLocalStorage))
    } else {
      fetchArtists().then(response => {
        setArtists(response.data)
        localStorage_setItem(localeStorageId, JSON.stringify(response.data))
      })
    }
  }, [process.env.STORAGE_SUFFIX])

  return artists
}
